.App {
  text-align: center;
  display: flex;
  max-width: 400px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.margin-top{
  margin-top: 2222px;
}

.linearBar{
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 15;
  width:100%;
  text-align: center;
  margin-top:15px;
}

.statusBar{
  text-align: center;
  width: 30%;
  text-align: right;
}

.buttonSubmit{
  width: 100%;
  margin-top:30px;
}

.sendEmail{
  margin-top:30px;
}
.errorWraper{
  width:100%;
  height: auto;
  margin-top:15px;
  padding:15px;
  box-sizing: border-box;
}

.errorItem{
  display: flex;
  margin-top:15px;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  column-gap: 15px;
}

.useCustomBg{
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.useCustomBg__checkbox{
  width:30px;
  padding:10px;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
}

.useCustomBg__checkbox::before{
  left: 0;
    width: 30px;
    height: 30px;
    content: "";
    display: block;
    position: absolute;
    margin-top: 0;
    padding:5px;
    cursor: pointer;
    /* background: rgb(94, 81, 81); */
}